import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { StoreSelection } from './import-products/StoreSelection'
import { WhiteBlackListing } from './import-products/WhiteBlackListing'
import { Review } from './import-products/Review'
import ProductList from './ProductList'

const productBreadCrumbs: Array<PageLink> = [
  {
    title: 'Products',
    path: '/products/product-list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/products/product-list'>
          <PageTitle breadcrumbs={productBreadCrumbs}>Product List</PageTitle>
          <ProductList />
        </Route>
        <Route path='/products/store-selection'>
          <PageTitle breadcrumbs={productBreadCrumbs}>Import Products</PageTitle>
          <StoreSelection />
        </Route>
        <Route path='/products/white-listing'>
          <PageTitle breadcrumbs={productBreadCrumbs}>Import Products</PageTitle>
          <WhiteBlackListing />
        </Route>
        <Route path='/products/review'>
          <PageTitle breadcrumbs={productBreadCrumbs}>Import Products</PageTitle>
          <Review />
        </Route>
      </Switch>
    </>
  )
}

export default ProductPage
