import {createSlice} from '@reduxjs/toolkit'
import {AlertTypeProps} from '../../components/Alert'

const initialState: AlertTypeProps = {}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    toggleAlert: (state, action) => action.payload,
  },
})

export const {toggleAlert} = alertSlice.actions
export default alertSlice.reducer
