import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router'
import { SubscriptionPlan } from './SubscriptionPlan'
import { AccountDetails } from './AccountDetails'
import { OrderSummary } from './OrderSummary'
import styled from 'styled-components'
import { AccountCreation } from '../account-creation/AccountCreation'

type Props = {}

const Registration: FC<Props> = () => {
  return (
    <Container>
      <Switch>
        <Route exact path='/registration/subscription-plan' component={SubscriptionPlan} />
        <Route exact path='/registration/account-details' component={AccountDetails} />
        {/* <Route exact path='/registration/payment-card' component={PaymentCard} /> */}
        <Route exact path='/registration/order-summary' component={OrderSummary} />
        <Route exact path='/registration/create-account' component={AccountCreation} />
        <Redirect to='/registration/create-account' />
      </Switch>
    </Container>
  )
}

export { Registration }

const Container = styled.div`
  margin: auto;
  margin-top: 48px;
  width: 80%;
`
