import {createRef, FC} from 'react'
import {FaChevronDown} from 'react-icons/fa'
import {StoreInfoType} from './Reports'
import styled from 'styled-components'
import Select from '../../components/Select'
import {useSelector} from 'react-redux'
import {activeStoreSelector} from '../../modules/selectors/reduxSelectors'
import {StoreType} from '../../../setup/types/response-data-types/ResponseDataTypes'

type Props = {
  info: StoreInfoType
  manageStoreSelection: (store: StoreType) => void
  manageSelectedChart: (chart: string) => void
}

const StoreInfo: FC<Props> = ({info, manageStoreSelection, manageSelectedChart}) => {
  const ref = createRef<any>()
  const storeOptions = useSelector(activeStoreSelector)
  return (
    <Container>
      <div className='header-info' onClick={() => ref?.current?._focus()}>
        <div className='header-info-label'>
          <span>
            Selected Store <FaChevronDown />
          </span>
        </div>
        <div className='header-info-value'>
          <span>{info?.selectedStore?.label}</span>
        </div>
        <div className='select-container'>
          <Select
            ref={ref}
            name='layout-builder[layout][header][width]'
            className='w-100'
            value={info?.selectedStore}
            onChange={(option) => manageStoreSelection(option)}
            options={storeOptions}
            withRs
            customControl
          />
        </div>
      </div>
      <div
        className={`header-info ${info?.selectedChart === 'Order' ? 'selected' : ''}`}
        onClick={() => manageSelectedChart('Order')}
      >
        <div className='header-info-label'>
          <span>Number Of Orders</span>
        </div>
        <div className='header-info-value'>
          <span>{info?.numOfOrders}</span>
        </div>
      </div>
      <div
        className={`header-info ${info?.selectedChart === 'Sale' ? 'selected' : ''} ${
          !info?.topCategory ? 'header-info-last' : ''
        }`}
        onClick={() => manageSelectedChart('Sale')}
      >
        <div className='header-info-label'>
          <span>Total Sales</span>
        </div>
        <div className='header-info-value'>
          <span>{info?.totalSales}</span>
        </div>
      </div>
      {info?.topCategory ? (
        <div className='header-info-readonly'>
          <div className='header-info-label'>
            <span>Top Category</span>
          </div>
          <div className='header-info-value'>
            <span>{info?.topCategory}</span>
          </div>
        </div>
      ) : null}
    </Container>
  )
}

export {StoreInfo}

const Container = styled.div`
  display: flex;

  .select-container {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .header-info,
  .header-info-readonly {
    width: 100%;
    height: 120px;
    margin: 24px 30px 0 0;
    border-radius: 8px;
    padding-block: 24px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
    border: 2px solid #ffffff;
  }

  .header-info {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // white-space: nowrap;
  }

  .header-info:hover,
  .selected {
    border: 2px solid #009ef7;
    box-shadow: none;
  }

  .header-info-readonly {
    margin-right: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .header-info-last {
    margin-right: 0;
  }

  .header-info-label {
    color: #a1a5bd;
    font-size: 16px;
    font-weight: 700;
  }

  .header-info-value {
    margin-top: 16px;
    color: #009ef7;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
