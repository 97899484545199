import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { FaChevronLeft } from 'react-icons/fa'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
  goBack: () => void
}

const PaymentProcessing: FC<Props> = ({ goBack }) => {
  const [percentage, setPercentage] = useState<number>(0)
  const [process, setProcess] = useState<string>('')
  const percentageRef = useRef<any>()
  useEffect(() => {
    percentageRef.current = setInterval(() => setPercentage((prev) => prev + 1), 50)
  }, [])

  useEffect(() => {
    if (percentage >= 120) {
      clearInterval(percentageRef.current)
      setProcess('successful')
    }
  }, [percentage])

  return (
    <Container>
      {process === 'successful' ? (
        <div className='processing'>
          <div className='payment-header'>Your payment is successful!</div>
          <div className='payment-successful-image'>
            <img
              src={toAbsoluteUrl('/media/downloaded-images/successful-payment.png')}
              alt=''
              width={150}
              height={150}
            />
          </div>
          <div className='payment-footer'>
            <span>You’ve successfully subscribed to 50.000</span>
            <span>listings plan.</span>
            <span>It is valid till January 12th, 2022</span>
            <span> To configure your first selling platform </span>
            <span className='mt-2 custom-link' onClick={goBack}>
              Add store details
            </span>
          </div>
        </div>
      ) : process === 'failed' ? (
        <div className='processing'>
          <div className='payment-header'>Something went wrong...</div>
          <div className='payment-successful-image'>
            <img
              src={toAbsoluteUrl('/media/downloaded-images/failed-payment.png')}
              alt=''
              width={220}
              height={220}
            />
          </div>
          <span className='mt-2 fs-4 custom-link' onClick={goBack}>
            <span>
              <FaChevronLeft /> Go back
            </span>
          </span>
        </div>
      ) : (
        <div className='processing'>
          <div className='payment-header'>
            <span>Payment Processing</span>
          </div>
          <div className='circle'>
            <CircularProgressbar
              value={percentage}
              text={''}
              minValue={0}
              maxValue={100}
              strokeWidth={15}
              styles={{
                // Customize the root svg element
                root: {},
                // Customize the path, i.e. the "completed progress"
                path: {
                  // Path color
                  stroke: `#009EF7`,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'round',
                  // Customize transition animation
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                  // Rotate the path
                  // transform: 'rotate(0.25turn)',
                  transformOrigin: 'center center',
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                  // Trail color
                  stroke: '#7892a50d',
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: 'butt',
                  // Rotate the trail
                  transform: 'rotate(0.25turn)',
                  transformOrigin: 'center center',
                },
                // Customize the text
                text: {
                  // Text color
                  fill: '#f88',
                  // Text size
                  fontSize: '16px',
                },
                // Customize background - only used when the `background` prop is true
                background: {
                  fill: '#3e98c7',
                },
              }}
            />
          </div>
          <div className='payment-footer'>
            <span>Wait a second, we are processing your payment...</span>
          </div>
        </div>
      )}
    </Container>
  )
}

export { PaymentProcessing }

const Container = styled.div`
  margin: auto;
  width: 464px;
  min-height: 410px;
  background-color: #7892a50d;
  box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
  border-radius: 24px;
  border: 1px solid #cdcfda;

  .processing {
    padding-block: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 410px;
  }

  .payment-header {
    font-size: 26px;
    font-weight: 600;
    color: #3f4254;
  }

  .payment-successful-image {
    padding-block: 12px;
  }

  .circle {
    width: 100px;
    height: 100px;
  }

  .payment-footer {
    font-size: 16px;
    font-weight: 500;
    color: #3f4254;
    display: flex;
    flex-direction: column;
  }

  .custom-link {
    color: #009ef7;
    cursor: pointer;
  }
`
