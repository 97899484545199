import axios from 'axios'
import {PagingType} from '../../types/request-data-types/RequestDataTypes'

export const brandApis = {
  async getPaging({pageNumber, pageSize}: PagingType, search: string, categoryIds?: string[]) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/Brands?page=${(pageNumber || 0) + 1}&pageSize=${
          pageSize || ''
        }&name=${search}`,
        categoryIds || []
      )
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
}
