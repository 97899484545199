import axios from 'axios'
import {getConfig} from '../../../app/utils/authUtils'
import {
  CreateStoreType,
  StoreDetailsType,
  ChangePaymentMethodType,
} from '../../types/request-data-types/RequestDataTypes'

export const storeApis = {
  async getAll() {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/Stores`)
      .then((res) => res.data)
      .catch((er) => {
        console.log(er)
        return {data: []}
      })
  },
  async create(requestData: CreateStoreType, token?: string) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/Stores`, requestData, getConfig(token || ''))
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
  async updateDetails(storeId: number, details: StoreDetailsType) {
    return await axios
      .put(`${process.env.REACT_APP_PROXY}/Stores/${storeId}`, details)
      .then((res) => res)
      .catch((er) => {
        console.log(er)
      })
  },
  async changePaymentMethod(requestData: ChangePaymentMethodType) {
    return await axios
      .put(
        `${process.env.REACT_APP_PROXY}/store/${requestData?.storeId}/payment-method?successCallbackUrl=${requestData.successCallbackUrl}&cancelCallbackUrl=${requestData.cancelCallbackUrl}`
      )
      .then((res) => res)
      .catch((er) => {
        console.log(er)
      })
  },
  async addSubscription(
    storeId: number,
    planId: string,
    successCallbackUrl: string,
    cancelCallbackUrl: string
  ) {
    return await axios.post(
      `${process.env.REACT_APP_PROXY}/store/${storeId}/subscription-plan/${planId}/subscription?successCallbackUrl=${successCallbackUrl}&cancelCallbackUrl=${cancelCallbackUrl}`
    )
  },
  async changePlan(storeId: number, planId: string) {
    return await axios.put(
      `${process.env.REACT_APP_PROXY}/store/${storeId}/subscription-plan/${planId}/subscription`
    )
  },
  async unSubscribe(storeId: number) {
    return await axios.put(`${process.env.REACT_APP_PROXY}/store/${storeId}/cancel-subscription`)
  },
  async reSubscribe(storeId: number) {
    return await axios.put(`${process.env.REACT_APP_PROXY}/store/${storeId}/resume-subscription`)
  },
  async connectToAmazon(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/AmazonAuth/Authorize?storeId=${storeId}`)
      .then((res) => res?.data)
  },
}
