import { FC } from 'react'
import { FaCheck } from 'react-icons/fa'
import { RiCloseLine } from 'react-icons/ri'
import styled from 'styled-components'

type Props = {
  data: PlanType1
  onChoose: (plan: PlanType1) => void
}

export type PlanType1 = {
  id: string
  title: string
  price: number
  active: boolean
  conditions: string[]
  discount?: string
  boxShadow?: string
  duration: number
  numOfStores: number
  numOfListings: number
  percentOfSale: number
}

const Plan: FC<Props> = ({ data, onChoose }) => {
  function _getConditions() {
    return data?.conditions?.map((condition, i) => {
      const priceDif = (data.id === '1' && i > 2) || (data.id === '2' && i > 3)
      return (
        <Condition
          key={i}
          color={priceDif ? '#bab8ce' : data.active ? '#ffffff' : '#000000'}
          priceDif={priceDif}
        >
          <span>
            {priceDif ? (
              <RiCloseLine fontSize={16} fontWeight={500} />
            ) : (
              <FaCheck fontSize={10} fontWeight={500} />
            )}{' '}
            {condition}
          </span>
        </Condition>
      )
    })
  }

  return (
    <Container active={data.active}>
      <div className='plan-header'>
        <span className='plan-title'>{data.title}</span>
        {data.discount ? (
          <div className='plan-discount'>
            <span>{data.discount}</span>
          </div>
        ) : null}
      </div>
      <div className='plan-body'>{_getConditions()}</div>
      <div className='plan-footer'>
        <div className='price'>
          <div className='price-val'>
            <div className='currency'>
              <span>$</span>
            </div>
            <div className='price-value'>
              <span>{data.price}</span>
            </div>
          </div>
          <span className='per-month'> /month</span>
        </div>

        <div className='action' onClick={() => onChoose(data)}>
          <span>
            {data.active
              ? data.title.includes('7 days')
                ? 'Try 7 days'
                : 'Try 1 month'
              : 'Choose'}
          </span>
        </div>
      </div>
    </Container>
  )
}

export default Plan

const Container = styled.div<any>(
  ({ active }) => `
  width: 330px;
  height: 450px;
  background-color: ${active ? '#009ef7' : '#eff2f5'};
  margin: ${active ? '12' : '48'}px 24px 12px 0;
  border-radius: 25px;
  border: 1px solid #cecfda;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  // box-shadow:${active ? 'none' : '0px 0px 10px 5px #ecebed'};
  white-space:nowrap;
  overflow-x:auto;
  animation-name:${active ? 'plan-active' : ''};
  animation-duration: 0.3s;

  :hover {
    box-shadow: ${active ? 'none' : '0px 0px 10px 5px #c9e5f5'};
    border:none;
  }


  @keyframes plan-active {
    0%   {margin-top: 48px;}
    100% {margin-top: 12px;}
  }

  .plan-header {
      padding:24px;
      display:flex;
      justify-content:space-between;
      align-items:center;
  }

  .plan-title {
    color:${active ? '#ffffff' : '#000000'};
    font-size:24px;
    font-weight:700;
  }

  .plan-discount {
      background-color: ${active ? '#ffffff' : '#009ef7'};
      color:${active ? '#000000' : '#ffffff'};
      font-size:14px;
      font-weight:700;
      padding:5px;
      border-radius:5px;
  }

  .plan-footer {
      padding: 0 24px 24px 24px;
      display:flex;
      flex-direction:column;
  }

  .action { 
      padding: ${active ? '5' : '10'}px;
      font-weight:700;
      font-size:${active ? 20 : 14}px;
      text-align: center;
      border-radius:8px;
      diplay: flex;
      align-items: center;
      justify-content: center;
      background-color: ${active ? '#e6f5fe' : '#009ef7'};
      color:${active ? '#009ef7' : '#ffffff'};
      cursor: pointer;
  }

  .action:hover {
    background-color: ${active ? '#f3faff' : '#80cffb'};
    color:${active ? '#009ef7' : '#ffffff'};
  }

  .action:active {
    background-color: ${active ? '#f3faff' : '#ccecfd'};
    color: #009ef7;
  }

  .price {
      display:flex;
  }

  .price-val {
    display:flex;
    align-items:center;
      margin-right:3px;
      margin-bottom:8px;
  }

  .currency {
    color: ${active ? '#ffffff' : '#2e2769'};
    font-size:19px;
    font-weight:500;
    margin-top:3px;
  }

  .price-value {
    color: ${active ? '#ffffff' : '#000000'};
    font-size:28px;
    font-weight:700;
  }

  .per-month {
    color: ${active ? '#ffffff' : '#625d8f'};
    display:inline-block;
    margin-top:20px;
    font-size:14px;
    font-weight:400;
  }

  .plan-body {
    flex-grow: 0;
  }


  @media  only screen  and (max-width:1210px) {
    height: 400px;

    .plan-title,
    .price-value {
      font-size:16px;
    }

    .per-month {
      font-size: 12px;
    }

    .action {
      font-size:${active ? 16 : 10}px;
    }

    .plan-discount {
      font-size: 10px;
      padding:2px;
    }
  }

`
)

const Condition = styled.div<any>(
  ({ color, priceDif }) => `
    padding:10px 24px;
    font-size:16px;
    color: ${color};
    text-indent:${priceDif ? 0 : 4}px;

    @media  only screen  and (max-width:1210px) {
        font-size:12px;
    }
  }
`
)
