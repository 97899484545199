import moment from 'moment'
import React, {useState} from 'react'
import {OrderStatus} from './OrderList'
import styled from 'styled-components'
import {CustomHeader} from '../../components/CustomStyledComponents'

type Props = {
  className?: string
  header: string
  data: OrderProductType[]
}

export type OrderProductType = {
  estimatedItemGross: number
  imageUrl: string
  itemPrice: number
  lineNumber: number
  lineTotalWithShipping: number
  matchedSupplierCode: string
  productId: number
  qty: number
  shippingTotal: number
  storeMicroOrderId: number
  storeSku: string
  title: string
  weight: number
}

const OrderItemTable: React.FC<Props> = ({header, data}) => {
  function getTableRows() {
    return data?.map((item: OrderProductType, index: number) => (
      <tr key={index} className={'custom-tbl-row'}>
        <td className='ps-1'>
          <img className='w-60px' src={item?.imageUrl} alt={item?.title} />
        </td>
        <td>
          <span className='custom-tbl-data'>{item?.title}</span>
        </td>
        <td className='pe-7'>
          <div className={`status-${OrderStatus.Pending?.toLocaleLowerCase()}`}>
            <span className='custom-tbl-data'>{OrderStatus.Pending}</span>
          </div>
        </td>
        <td>
          <span className='custom-tbl-data'>{item?.qty}</span>
        </td>
        <td>
          <span className='custom-tbl-data'>{`${item?.itemPrice} USD`}</span>
        </td>
        <td>
          <span className='custom-tbl-data'>{`${item?.shippingTotal} USD`}</span>
        </td>
        <td>
          <div className='d-flex flex-column'>
            <span className='custom-tbl-data'>2 Days</span>
            <span className='custom-tbl-data'>{`${item?.weight} Ounces`}</span>
          </div>
        </td>
        <td>
          <span className='custom-tbl-data'>{item?.storeSku}</span>
        </td>
        <td>
          <span className='custom-tbl-data'>{moment(new Date()).format('lll')}</span>
        </td>
        {/* <td>
            <div className='d-flex flex-column'>
              <span className='custom-tbl-data'>{`Total: ${item?.shippingTotal}`}</span>
              <span className='custom-tbl-data'>{`Base: ${item?.shippingTotal}`}</span>
              <span className='custom-tbl-data'>{`Markup: ${item?.shippingTotal}`}</span>
              <span className='custom-tbl-data'>{`Shipping: ${item?.shippingTotal}`}</span>
            </div>
          </td> */}
      </tr>
    ))
  }
  return (
    <Container>
      {/* begin::Header */}
      <CustomHeader>Items</CustomHeader>
      {/* end::Header */}
      {/* begin::Body */}
      <div>
        {/* begin::Table container */}
        <div className='table-responsive table-wrapper table-div'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead className='sticky-top bg-white'>
              <tr className='custom-tbl-head'>
                <th className='w-100px ps-3 rounded-top-start'>Image</th>
                <th className='w-125px'>Title</th>
                <th className='w-125px'>Status</th>
                <th className='w-100px'>Quantity</th>
                <th className='w-100px'>Price</th>
                <th className='w-125px'>Shipping total</th>
                <th className='w-100px'>Lead/weight</th>
                <th className='w-100px'>SKU</th>
                <th className='w-100px'>Verified date</th>
                {/* <th className='w-100px pe-3 rounded-top-end'>Purchase USD</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>{getTableRows()}</tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </Container>
  )
}

export {OrderItemTable}

const Container = styled.div`
  thead {
    z-index: 1;
  }

  .table-wrapper {
    height: 50vh;
    overflow: auto;
    margin: 0 12px 24px 12px;
    padding-inline: 12px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scscrollbar-height: 6px;
    rollbar-color: #40b6f9 #e4e6ef;
  }

  .custom-tbl-head {
    background-color: #d9f1fe;
    color: #525869;
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
  }

  .custom-tbl-data {
    font-size: 14px;
  }

  .custom-tbl-row {
    height: 80px;
  }

  .custom-tbl-row:hover {
    background-color: #f3faff;
    .status-shipped {
      border: 1px solid #50cd89;
    }

    .status-unshipped {
      border: 1px solid #f1416c;
    }

    .status-pending {
      border: 1px solid #ffc700;
    }
  }

  .status-shipped,
  .status-unshipped,
  .status-pending {
    padding: 5px;
    border-radius: 5px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .status-shipped {
    background-color: #e8fff3;
    color: #50cd89;
  }

  .status-unshipped {
    background-color: #fff5f8;
    color: #f1416c;
  }

  .status-pending {
    background-color: #fff8dd;
    color: #ffc700;
  }

  .icon {
    margin: 0 0 1px 1px;
    cursor: pointer;
  }

  .date-container {
    position: absolute;
    top: -2px;
    left: 4px;
    cursor: pointer;
  }

  .filter-parent {
    position: relative;
  }

  .filter-table {
    position: absolute;
    top: 45px;
    left: 0px;
    width: 300px;
    height: 150px;
    display: flex;
    background-color: white;
    border: 2px solid #a1a5b7;
    border-radius: 5px;
  }

  .filter-status,
  .filter-string-input,
  .filter-number-input {
    width: 150px;
    height: 110px;
    display: flex;
    flex-direction: column;
  }

  .filter-string-input {
    width: 300px;
    height: 150px;
    flex-direction: column;
    height: 100px;
    align-items: center;
    input {
      margin-top: 20px;
      height: 30px;
      width: 80%;
      text-indent: 20px;
      border: 1px solid #cccccc;
    }

    input:focus {
      outline: none;
      border: 1px solid #2684ff;
      box-shadow: 0 0 1px 2px #c0e7fd;
    }

    .search-icon {
      position: absolute;
      top: 24px;
      left: 40px;
    }
  }

  .filter-number-input {
    color: black;
    font-weight: 500;
    font-size: 12px;
    width: 270px;
    overflow: auto;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    input {
      padding: 5px;
      height: 30px;
      font-size: 13px;
      width: 30%;
    }

    // /* Chrome, Safari, Edge, Opera */
    // input::-webkit-outer-spin-button,
    // input::-webkit-inner-spin-button {
    //   -webkit-appearance: none;
    //   margin: 0;
    // }

    // /* Firefox */
    // input[type='number'] {
    //   -moz-appearance: textfield;
    // }
  }

  .notes {
    left: -100px;
  }

  .note-input {
    border-radius: 5px;
    padding: 4px 8px;
    border: 1px solid #cccccc;
    font-size: 14px;
  }

  .note-input:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }

  .custom-btn {
    background-color: #009ef7;
    color: #ffffff;
    padding: 4px 12px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  .custom-btn-primary {
    background-color: #009ef7;
    color: #ffffff;
  }

  .custom-btn-secondary {
    background-color: #e4e6ef;
    color: #000000;
  }

  .custom-btn-primary:hover {
    background-color: #80cffb;
    color: #ffffff;
  }

  .custom-btn-secondary:hover {
    background-color: #f3faff;
    color: #009ef7;
  }

  .custom-btn-primary:active {
    background-color: #037fc5;
    color: #ffffff;
  }

  .custom-btn-secondary:active {
    background-color: #a9dffc;
    color: #009ef7;
  }

  .note-span {
    cursor: pointer;
  }

  .note-span:hover {
    text-decoration: underline;
  }

  .note-td {
    white-space: normal;
    word-break: break-all;
  }

  .rounded-top-start {
    border-top-left-radius: 8px;
  }

  .rounded-top-end {
    border-top-right-radius: 8px;
  }
`
