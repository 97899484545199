import axios from 'axios'
import { getConfig } from '../../../app/utils/authUtils'
import { PagingType } from '../../types/request-data-types/RequestDataTypes'

export const notificationApis = {
    async getPaging({ pageNumber, pageSize }: PagingType, storeId: number, notificationType: string) {
        return await axios
            .get(
                `${process.env.REACT_APP_PROXY}/Notification?Page=${(pageNumber || 0) + 1}&PageSize=${pageSize || ''
                }&StoreId=${storeId}&NotificationType=${notificationType}`,
            )
            .then((res) => res?.data)
            .catch((er) => console.log('error', er))
    },
    async markAsRead(storeId: number, notificationType: string, token: string) {
        return await axios
            .post(
                `${process.env.REACT_APP_PROXY}/Notification/mark-notifications-as-read`,
                { storeId, notificationType },
                getConfig(token)
            )
            .then((res) => res)
            .catch((er) => console.log('error', er))
    },
    async getUnreadCount(storeId: number) {
        return await axios
            .get(
                `${process.env.REACT_APP_PROXY}/Notification/get-unread-notifications-count?storeId=${storeId}`,
            )
            .then((res) => res.data)
            .catch((er) => console.log('error', er))
    },
}
