import {FC} from 'react'
import {Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Reports} from './Reports'

const reportBreadCrumbs: Array<PageLink> = [
  {
    title: 'Dashboard/Reports',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportPage: FC = () => {
  return (
    <>
      <Switch>
        <Route path='/dashboard'>
          <PageTitle breadcrumbs={reportBreadCrumbs}>Dashboard</PageTitle>
          <Reports />
        </Route>
      </Switch>
    </>
  )
}

export default ReportPage
