import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ContactUs } from './ContactUs'

const contactBreadCrumbs: Array<PageLink> = [
    {
        title: 'Contact page',
        path: '/contact-us',
        isSeparator: false,
        isActive: false,
    },
]

const ContactPage: React.FC = () => {
    return (
        <>
            <Switch>
                <Route path='/contact-us'>
                    <PageTitle breadcrumbs={contactBreadCrumbs}>Contact Us</PageTitle>
                    <ContactUs fromInner />
                </Route>
            </Switch>
        </>
    )
}

export default ContactPage
