import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'
import { CustomHeader, CustomLoading } from '../../components/CustomStyledComponents'
import { categoryApis } from '../../../setup/apis/category/categoryApis'

type Props = {}

export type CategoryType = {
  categoryId: string
  categoryName: string
  childCategoryCount: number
  parentCategoryId: string | null
  parentCategoryName: string | null
  productCount: number
  totalProductCount: number
  visible?: boolean
  selected?: boolean
}

const CategoryList: FC<Props> = () => {
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const observer = useRef<any>()
  const lastRowRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1)
          _getCategories(pageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading]
  )

  useEffect(() => {
    _getCategories(pageNumber)
  }, [])

  async function _getCategories(page: number) {
    if (!hasMore) return
    setLoading(true)
    try {
      const res = await categoryApis.getAll({ page, pageSize: 50 })
      const data = res?.data || []
      const totalCount = res?.totalCount
      setHasMore([...categories, ...data].length < totalCount)
      setCategories((prev) => [...prev, ...data])
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  async function _getChildCategories(parentId: string) {
    try {
      const res = await categoryApis.getAll({ parentIds: [parentId] })
      const data = res?.data || []
      setCategories((prev) => [...prev, ...data])
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  function isVisible(cat: CategoryType) {
    return categories?.find((item) => item.categoryId === cat.categoryId)?.visible
  }

  function isSelected(cat: CategoryType) {
    return categories?.find((item) => item.categoryId === cat.categoryId)?.selected
  }

  function _onSelectCategory(cat: CategoryType) {
    setCategories((prev) =>
      prev.map((item) => ({
        ...item,
        visible: item.categoryId === cat.categoryId ? !item.visible : item.visible,
        selected: item.categoryId === cat.categoryId,
      }))
    )
    if (
      cat.childCategoryCount &&
      categories.findIndex((item) => item.parentCategoryId === cat.categoryId) === -1
    ) {
      _getChildCategories(cat.categoryId)
    }
  }

  const infoText = "Couldn't fetch the categories..."

  function getList(list: CategoryType[], indent: number) {
    indent += 20
    return (
      <div>
        {list.map((cat: CategoryType, index: number) => (
          <Row
            key={index}
            indent={indent}
            ref={
              list.filter((item) => !item.parentCategoryId).length === index + 1 ? lastRowRef : null
            }
          >
            <div
              className={`row-wrapper ${isSelected(cat) && 'selected'}`}
              onClick={(e) => _onSelectCategory(cat)}
            >
              <span className={`title ${isVisible(cat) && cat.childCategoryCount && 'fw-bolder'}`}>
                {cat.childCategoryCount ? (
                  isVisible(cat) ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronRight />
                  )
                ) : null}{' '}
                {cat.categoryName}
              </span>
              <span className='category-id'>{cat.categoryId}</span>
              {/* <span className='actions'>
                <FaSearch color='#7f7f7f' />
                {` `}
                <FaAmazon />
              </span> */}
              <span className='num-of-products'>{cat.totalProductCount}</span>
            </div>
            {cat.childCategoryCount && isVisible(cat)
              ? getList(
                categories.filter((item) => item.parentCategoryId === cat.categoryId),
                indent
              )
              : null}
          </Row>
        ))}
      </div>
    )
  }

  return (
    <Container className='card'>
      <div className='card-header align-items-center p-0'>
        <CustomHeader>Categories</CustomHeader>
      </div>
      <hr className='m-0' />
      <div className='card-body px-0 pt-1 w-100 h-100'>
        <div>
          <div className='category-header'>
            <span className='title'>Categories</span>
            <span className='category-id'>Category ID</span>
            <span className='number-of-items'>Number of products</span>
          </div>
          <div className='list-container'>
            {getList(
              categories.filter((item) => item?.parentCategoryId === null),
              4
            )}
          </div>
        </div>
        {!categories.length ? (
          <CustomLoading>
            <div className='text'>
              <span>{loading ? 'Loading...' : infoText}</span>
            </div>
          </CustomLoading>
        ) : null}
        {loading && categories.length ? (
          <div className='h-100 d-flex justify-content-center align-items-center fs-3 fw-bold mt-5'>
            <span>Loading...</span>
          </div>
        ) : null}
      </div>
    </Container>
  )
}

export { CategoryList }

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-height: 80vh;
  margin-bottom:48px;

  border-radius: 8px;
  min-width: 1168px;
  overflow-x: auto;

  .category-header {
    white-space: nowrap;
    height: 60px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    border-bottom: 1px solid #cccccc;

    .title,
    .category-id,
    .actions,
    .number-of-items {
      display: inline-block;
    }

    .title {
      text-indent: 24px;
      width: 600px;
    }

    .category-id {
      width: 350px;
    }
  }

  .selected {
    background-color: #e4f5ff;
  }

  .custom-hr {
    margin: 0;
    border-bottom: 1px solid #cccccc;
  }
`

const Row = styled.div<any>(
  (props) => `
  span {
    cursor:pointer;
  }

  .row-wrapper {
      padding-block: 10px ;
      font-size: 16px;
      border-bottom: 1px solid #cccccc;
  }

  .title,
  .category-id,
  .actions,
  .number-of-items {
    display: inline-block;
    font-size: 16px; 
  }

  .title { 
    text-indent:${props.indent}px;
    width:600px;
  }

  .category-id { 
    width:350px;
  }

  .actions { 
    width:150px;
  }
`
)
