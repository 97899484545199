import {FC, useEffect, useMemo, useRef} from 'react'
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import {ButtonSecondary, CustomHeader} from '../../components/CustomStyledComponents'
import Select from '../../components/Select'
import {RegistrationType} from './SubscriptionPlan'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import MaskedInput from 'react-text-mask'

export type CardType = {
  name: string
  cardNumber: string
  expiration: string
  cvv: string
  address: string
  addressAdditional: string
  country: string
  city: string
  state: string
  postalCode: string
}

const cardRegExp = /4\d{3} \d{4} \d{4} \d{4}/
const cardNumberMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
const expirationMask = [/\d/, /\d/, '/', /\d/, /\d/]
const cvvMask = [/\d/, /\d/, /\d/]

const regSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name on card is required'),
  cardNumber: Yup.string()
    .trim()
    .required('Card number is required')
    .matches(cardRegExp, 'Card number is not valid'),
  country: Yup.string().trim().required('Country is required'),
  expiration: Yup.string().trim().required('Expiration date is required'),
  cvv: Yup.string()
    .trim()
    .required('CVV is required')
    .min(3, 'CVV is not valid')
    .max(3, 'CVV is not valid'),
  address: Yup.string().trim().required('Address is required'),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  postalCode: Yup.string().trim().required('Postal code is required'),
})

export const initialCard = {
  name: '',
  cardNumber: '',
  expiration: '',
  cvv: '',
  address: '',
  addressAdditional: '',
  country: '',
  city: '',
  state: '',
  postalCode: '',
}

const PaymentCard: FC = () => {
  const history = useHistory<RegistrationType>()
  const state = history.location?.state
  const data = useRef<CardType>(initialCard)
  const lenRef = useRef<number>(0)

  useEffect(() => {
    if (state?.details) {
      data.current = state.card
    }
  }, [])

  const formik = useFormik({
    initialValues: data.current,
    validationSchema: regSchema,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {
      console.log('submitted')
    },
  })

  const valid = useMemo(
    () => !Object.values(formik.values).some((item) => item === ''),
    [formik.values]
  )

  function _previousPage() {
    history.push('/registration/account-details', {
      plan: state.plan,
      details: state?.details,
      card: formik.values,
    })
  }

  function _nextPage() {
    history.push('/registration/order-summary', {
      plan: state.plan,
      card: formik.values,
      details: state?.details,
    })
  }

  return (
    <Container className='card'>
      <div className='card-header align-items-center p-0'>
        <CustomHeader>Payment Card</CustomHeader>
        <div className='header-buttons'>
          <ButtonSecondary onClick={() => _previousPage()}>
            <FaChevronLeft /> Previous Step
          </ButtonSecondary>
          <ButtonSecondary disabled={!valid || !formik.isValid} onClick={() => _nextPage()}>
            Next Step <FaChevronRight />
          </ButtonSecondary>
        </div>
      </div>
      <hr className='m-0' />
      <div className='details-body'>
        <div className='plan-info'>
          <span className='payment-method-label'>Payment Method</span>
          <span className='plan-title'>Stripe</span>
        </div>
        <form className='fform'>
          <div className='fform-row'>
            <div className='fform-item'>
              <label className='input-label'>Name on card</label>
              <input
                type='text'
                placeholder='JOHN DOE'
                autoComplete='off'
                {...{...formik.getFieldProps('name'), value: formik.getFieldProps('name').value}}
                className={clsx(
                  'form-control form-control-lg form-control-solid input-value-name',
                  {'is-invalid': formik.touched.name && formik.errors.name},
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fform-item'>
              <label className='input-label'>Card number</label>
              <MaskedInput
                mask={cardNumberMask}
                type='text'
                autoComplete='off'
                placeholder='4111 1111 1111 1111'
                {...formik.getFieldProps('cardNumber')}
                className={clsx(
                  'form-control form-control-lg form-control-solid input-value',
                  {'is-invalid': formik.touched.cardNumber && formik.errors.cardNumber},
                  {
                    'is-valid': formik.touched.cardNumber && !formik.errors.cardNumber,
                  }
                )}
              />
              {formik.touched.cardNumber && formik.errors.cardNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cardNumber}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fform-row'>
            <div className='fform-row-specific'>
              <div className='fform-item-specific-left'>
                <label className='input-label'>Expiration</label>
                <MaskedInput
                  mask={expirationMask}
                  guide={true}
                  type='text'
                  autoComplete='off'
                  required
                  placeholder='MM/YY'
                  {...formik.getFieldProps('expiration')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid input-value',
                    {'is-invalid': formik.touched.expiration && formik.errors.expiration},
                    {
                      'is-valid': formik.touched.expiration && !formik.errors.expiration,
                    }
                  )}
                />
                {formik.touched.expiration && formik.errors.expiration && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.expiration}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fform-item-specific'>
                <label className='input-label'>CVV</label>
                <MaskedInput
                  mask={cvvMask}
                  guide={true}
                  type='text'
                  autoComplete='off'
                  placeholder='Insert cvv'
                  {...formik.getFieldProps('cvv')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid input-value',
                    {'is-invalid': formik.touched.cvv && formik.errors.cvv},
                    {
                      'is-valid': formik.touched.cvv && !formik.errors.cvv,
                    }
                  )}
                />
                {formik.touched.cvv && formik.errors.cvv && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.cvv}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='fform-item'>
              <label className='input-label'>City</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Insert city'
                {...formik.getFieldProps('city')}
                className={clsx(
                  'form-control form-control-lg form-control-solid input-value',
                  {'is-invalid': formik.touched.city && formik.errors.city},
                  {
                    'is-valid': formik.touched.city && !formik.errors.city,
                  }
                )}
              />
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.city}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fform-row'>
            <div className='fform-item'>
              <label className='input-label'>Address</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Insert address'
                {...formik.getFieldProps('address')}
                className={clsx(
                  'form-control form-control-lg form-control-solid input-value',
                  {'is-invalid': formik.touched.address && formik.errors.address},
                  {
                    'is-valid': formik.touched.address && !formik.errors.address,
                  }
                )}
              />
              {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.address}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fform-item'>
              <label className='input-label'>Address (additional)</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Insert additional address'
                {...formik.getFieldProps('addressAdditional')}
                className={clsx('form-control form-control-lg form-control-solid input-value')}
              />
            </div>
          </div>
          <div className='fform-row'>
            <div className='fform-item'>
              <label className='input-label'>Country</label>
              <Select
                options={countryOtions}
                {...formik.getFieldProps('country')}
                className={clsx(
                  'form-select form-select-solid bold-input input-value',
                  {'is-invalid': formik.touched.country && formik.errors.country},
                  {
                    'is-valid': formik.touched.country && !formik.errors.country,
                  }
                )}
              />
              {formik.touched.country && formik.errors.country && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.country}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fform-item'>
              <label className='input-label'>Postal Code</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Insert postal code'
                {...formik.getFieldProps('postalCode')}
                className={clsx(
                  'form-control form-control-lg form-control-solid input-value',
                  {'is-invalid': formik.touched.postalCode && formik.errors.postalCode},
                  {
                    'is-valid': formik.touched.postalCode && !formik.errors.postalCode,
                  }
                )}
              />
              {formik.touched.postalCode && formik.errors.postalCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.postalCode}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fform-row'>
            <div className='fform-item'>
              <label className='input-label'>State (enter "none" if there is no state)</label>
              <input
                type='text'
                autoComplete='off'
                placeholder='Insert state'
                {...formik.getFieldProps('state')}
                className={clsx(
                  'form-control form-control-lg form-control-solid input-value',
                  {'is-invalid': formik.touched.state && formik.errors.state},
                  {
                    'is-valid': formik.touched.state && !formik.errors.state,
                  }
                )}
              />
              {formik.touched.state && formik.errors.state && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.state}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fform-item'></div>
          </div>
        </form>
      </div>
    </Container>
  )
}

export {PaymentCard}

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 2px #cccccc;

  .header-buttons {
    padding: 0 24px;

    button {
      margin-left: 12px;
    }
  }

  .details-body {
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: start;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
  }

  .payment-method-label {
    font-size: 15px;
    font-weight: 700;
  }

  .plan-title {
    font-size: 24px;
    font-weight: 700;
  }

  .fform {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
  }

  .fform-row,
  .fform-row-specific {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .fform-row-specific {
    width: 80%;
    margin-right: 60px;
    justify-content: space-between;
  }

  .fform-item,
  .fform-item-specific,
  .fform-item-specific-left {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 60px;
    padding: 12px 0;
  }

  .fform-item-specific {
    margin-right: 0;
  }

  .fform-item-specific-left {
    margin-right: 20px;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value,
  .input-value-name {
    width: 100%;
    background-color: #eff2f5;
  }

  .input-value-name {
    text-transform: uppercase;
  }

  .input-value:focus {
    outline: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`
const countryOtions = [
  {
    value: '',
    label: 'Select Country',
  },
  {
    value: 'USA',
    label: 'USA',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'UK',
    label: 'UK',
  },
  {
    value: 'Germany',
    label: 'Germany',
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
  },
]
