import { FC } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../setup'
import store from '../../setup/redux/Store'
import { toggleAlert } from '../modules/redux-toolkit/alertSlicer'

export type AlertTypeProps = {
  type?: string
  color?: string
  text?: string
  visible?: boolean
}

export const triggerAlert = (text: string, type?: string, delay?: number) => {
  store.dispatch(toggleAlert({ text, visible: true, type }))
  setTimeout(() => store.dispatch(toggleAlert({ text: '', visible: false })), delay || 3000)
}

const Alert: FC<AlertTypeProps> = ({ type = 'success', text }) => {
  const alert = useSelector((st: RootState) => st.alert)
  const bgColor = type === 'error' ? '#FDEAF0' : '#D9FFEB'
  const color = type === 'error' ? '#F1416C' : '#3F4254'
  return (
    <Container bgColor={bgColor} color={color} display={alert?.visible ? 'flex' : 'none'}>
      <span>{text}</span>
    </Container>
  )
}

export { Alert }

const Container = styled.div<any>(
  ({ bgColor, color, display }) => `
    position:fixed;
    top:30px;
    left:50%;
    min-width: 367px;
    transform:translateX(-50%);
    z-index:99999999;
    padding:30px;
    border-radius:8px;
    font-size:18px;
    font-weight:500;
    display:${display};
    align-items:center;
    justify-content:center;
    background-color:${bgColor};
    color:${color};

    @media  screen and (min-width: 1000px) {
      white-space:nowrap;
    }`
)
