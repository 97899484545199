export const preventSpaces = (e: any) => {
  if (e.key === ' ') {
    e.preventDefault()
    return
  }
  if (e.clipboardData?.getData('Text') && !e.clipboardData?.getData('Text')?.trim()) {
    e.preventDefault()
  }
}

export const preventMinuses = (e: any) => {
  if (e.key === '-') e.preventDefault()
}

export const preventNumbers = (e: any) => {
  if (e.keyCode >= 48 && e.keyCode <= 57) e.preventDefault()
}

export const onlyNumbers = (e: any) => {
  if (
    (e.key !== 'ArrowRight' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.keyCode < 48) ||
    e.keyCode > 57
  )
    e.preventDefault()
}

export const preventSpacesAndNumbers = (e: any) => {
  if (e.key === ' ' || (e.keyCode >= 48 && e.keyCode <= 57)) e.preventDefault()
}

export const only2Words = (e: any) => {
  const words = e.target.value?.trim()?.split(' ')
  if (words?.length >= 2 && e?.key === ' ') {
    e.preventDefault()
  }
}

export const nameValidation = (e: any) => {
  const permittedSymbols = ['.', '-', "'", 'ArrowRight', 'ArrowLeft', 'Backspace', ' ', 'Tab']
  if ((e.keyCode < 65 || e.keyCode > 90) && !permittedSymbols.includes(e.key)) {
    e.preventDefault()
  }
  if (
    e.clipboardData?.getData('Text') &&
    e.clipboardData
      ?.getData('Text')
      ?.split('')
      ?.some(
        (letter: string) =>
          (letter.charCodeAt(0) < 65 || letter.charCodeAt(0) > 90) &&
          !permittedSymbols.includes(letter.charAt(0))
      )
  ) {
    e.preventDefault()
  }
}

export const limitateValueLength = (e: any, requiredLength: number) => {
  if (
    e.key === ' ' ||
    (e.key !== 'ArrowRight' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.target.value.length > requiredLength)
  )
    e.preventDefault()
}

export const capitalize = (text: string) => {
  return text
    ?.split(' ')
    ?.map((item) => item?.[0]?.toUpperCase()?.concat(item?.substring(1)) || '')
    ?.join(' ')
}
