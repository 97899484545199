import axios from 'axios'

export const pricingPointApis = {
  async getAll() {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/PricingPoint`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
}
