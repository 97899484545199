import {createSlice} from '@reduxjs/toolkit'
import {StoreType} from '../../../setup/types/response-data-types/ResponseDataTypes'

const initialState: StoreType[] = []

const storeSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    getStores: (state, action) => action.payload,
  },
})

export const {getStores} = storeSlice.actions
export default storeSlice.reducer
