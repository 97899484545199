import { FC, useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ButtonSecondary, CustomHeader } from '../../components/CustomStyledComponents'
import { DetailsType, initialDetails } from '../account-creation/AccountCreation'
import { CardType, initialCard } from './PaymentCard'
import Plan, { PlanType1 } from './Plan'

type Props = {}

export const initialPlans = [
  {
    id: '1',
    title: 'Starter (7 days free)',
    price: 18,
    conditions: [
      'Up to 5000 listings',
      '1 store',
      'No percentage of sale',
      'Exclusive Deals',
      'Advanced statistics',
    ],
    active: false,
    boxShadow: '0px 0px 10px 5px #c9e5f5',
    duration: 7,
    numOfStores: 1,
    numOfListings: 5000,
    percentOfSale: 0,
  },
  {
    id: '2',
    title: 'Universal',
    price: 123,
    discount: 'Save $40',
    conditions: [
      'Up to 10000 listings',
      '10 stores',
      '5 percents of sale',
      'Exclusive Deals',
      'Advanced statistics',
    ],
    active: false,
    boxShadow: '0px 0px 10px 5px #ecebed',
    duration: 30,
    numOfStores: 10,
    numOfListings: 10000,
    percentOfSale: 5,
  },
  {
    id: '3',
    title: 'Premium',
    price: 200,
    conditions: [
      'Up to 20000 listings',
      '20 stores',
      '2 percents of sale',
      'Exclusive Deals',
      'Advanced statistics',
    ],
    active: false,
    boxShadow: '0px 0px 10px 5px #ecebed',
    duration: 30,
    numOfStores: 20,
    numOfListings: 20000,
    percentOfSale: 2,
  },
]

export type RegistrationType = {
  plan: PlanType1
  details: DetailsType
  card: CardType
}

const SubscriptionPlan: FC<Props> = () => {
  const history = useHistory<RegistrationType>()
  const state = history.location?.state
  const [plans, setPlans] = useState<PlanType1[]>(initialPlans)
  const [selectedPlan, setSelectedPlan] = useState<PlanType1>(initialPlans[0])

  useEffect(() => {
    if (state?.plan?.id) {
      setSelectedPlan(state.plan)
      setPlans((prevData) =>
        prevData.map((item) => ({ ...item, active: item.id === state?.plan?.id ? true : false }))
      )
    }
  }, [])

  function _tryOneMonth(plan: PlanType1) {
    setSelectedPlan(plan)
  }

  function _nextPage() {
    const fromSettings = history.location.pathname.includes('settings')
    history.push(fromSettings ? '/settings/order-summary' : '/registration/account-details', {
      plan: selectedPlan,
      details: state?.details || initialDetails,
      card: state?.card || initialCard,
    })
  }

  function _onChoose(plan: PlanType1) {
    if (plan.active) {
      _tryOneMonth(plan)
    } else {
      setPlans((prevData) =>
        prevData.map((item) => ({ ...item, active: item.id === plan.id ? true : false }))
      )
      setSelectedPlan((prevData) => ({ ...prevData, active: false }))
    }
  }

  function _getPlans() {
    return plans.map((plan: PlanType1, i) => <Plan key={i} data={plan} onChoose={_onChoose} />)
  }
  return (
    <Container className='card'>
      <div className='card-header align-items-center p-0 plans-header'>
        <CustomHeader>Subscription Plan</CustomHeader>
        <ButtonSecondary disabled={!selectedPlan.active} onClick={() => _nextPage()}>
          Next Step <FaChevronRight />
        </ButtonSecondary>
      </div>
      <hr className='m-0' />
      <div className='plans-body'>{_getPlans()}</div>
    </Container>
  )
}

export { SubscriptionPlan }

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-width: 800px;
  overflow-x: auto;

  .plans-header {
    margin-right: 24px;
  }

  .plans-body {
    display: flex;
    padding: 24px;
    padding-bottom: 96px;
    justify-content: center;
  }
`
