import axios from 'axios'
import {getConfig} from '../../../app/utils/authUtils'

export const planApis = {
  async getAll(token?: string) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/subscriptionPlans`, getConfig(token || ''))
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
}
