import {useEffect, useRef, useState} from 'react'
import {CircularProgressbar} from 'react-circular-progressbar'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {accountApis} from '../../../setup/apis/account/accountApis'
import {ButtonSecondary} from '../../components/CustomStyledComponents'
import {useQuery} from '../../utils/customHooks'

function EmailConfirmation() {
  const [percentage, setPercentage] = useState<number>(0)
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const [processing, setProcessing] = useState<string>('')
  const percentageRef = useRef<any>()
  const history = useHistory()
  const query = useQuery()
  useEffect(() => {
    percentageRef.current = setInterval(() => setPercentage((prev) => prev + 1), 50)
    confirmEmail()
  }, [])

  useEffect(() => {
    if (percentage >= 120) {
      clearInterval(percentageRef.current)
    }
  }, [percentage])

  function backToLogin() {
    history.push('/auth')
  }

  async function confirmEmail() {
    const userId = query.get('userId') || ''
    const token = query.get('token')?.replaceAll(' ', '+') || ''
    try {
      const response = await accountApis.confirmEmail(userId, token)
      if (response) {
        setProcessing('success')
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <Container>
      <div className='processing'>
        <div className='payment-header text-nowrap'>
          {!processing ? <span>Confirming your email...</span> : null}
          {processing === 'fail' ? <span>Email confirmation failed.</span> : null}
          {processing === 'success' ? <span>You email has been confirmed!</span> : null}
        </div>
        <div className='circle'>
          <CircularProgressbar
            value={percentage}
            text={''}
            minValue={0}
            maxValue={100}
            strokeWidth={15}
            styles={{
              root: {},
              path: {
                stroke: `#009EF7`,
                strokeLinecap: 'round',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: '#7892a50d',
                strokeLinecap: 'butt',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              text: {
                fill: '#f88',
                fontSize: '16px',
              },
              background: {
                fill: '#3e98c7',
              },
            }}
          />
        </div>
        <div className='payment-footer'>
          {processing ? (
            <ButtonSecondary onClick={backToLogin} className='mt-5 align-self-center'>
              Back to login
            </ButtonSecondary>
          ) : null}
        </div>
      </div>
    </Container>
  )
}

export {EmailConfirmation}

const Container = styled.div`
  margin: auto;
  .processing {
    padding-block: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 300px;
  }

  .payment-header {
    font-size: 26px;
    font-weight: 600;
    color: #3f4254;
  }

  .payment-successful-image {
    padding-block: 12px;
  }

  .circle {
    width: 100px;
    height: 100px;
  }

  .payment-footer {
    font-size: 16px;
    font-weight: 500;
    color: #3f4254;
    display: flex;
    flex-direction: column;
  }

  .custom-link {
    color: #009ef7;
    cursor: pointer;
  }
`
