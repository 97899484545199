import {createSlice} from '@reduxjs/toolkit'
import {PlanOptionType} from '../../layger/account-creation/AccountCreation'

const initialState: PlanOptionType[] = []

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    getPlans: (state, action) => action.payload,
  },
})

export const {getPlans} = planSlice.actions
export default planSlice.reducer
