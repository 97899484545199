import React, { FC, useEffect, useState } from 'react'
import ReactPagination from 'react-paginate'
import { PagingType } from '../../setup/types/request-data-types/RequestDataTypes'
import Select from './Select'
import styled from 'styled-components'

type Props = {
  totalCount: number
  pageSize?: number
  currentPage?: number
  onPageChange: (target: any) => void
  onPageSizeChange: (pageSize: number) => void
  withPageSizeSelect?: boolean
}

const Pagination: FC<Props> = ({
  totalCount,
  currentPage = 0,
  pageSize = 100,
  onPageChange,
  onPageSizeChange,
  withPageSizeSelect,
}) => {
  const from = currentPage * pageSize
  const to = from + pageSize > totalCount ? totalCount : from + pageSize
  return (
    <Container>
      <div className='pagination-info-container'>
        <span className='pagination-info'>{`Showing from ${from} to ${to} of ${totalCount} entries`}</span>
        {/* {withPageSizeSelect ? (
          <div className='page-size-select ms-20'>
            <Select
              name='layout-builder[layout][header][width]'
              value={pageSizeOptions.find((option) => option.value === pageSize)}
              onChange={(option) => onPageSizeChange(option.value)}
              options={pageSizeOptions}
              withRs
              menuPlacement='top'
              forPaging
            />
          </div>
        ) : null} */}
      </div>
      <ReactPagination
        pageCount={totalCount / pageSize}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={currentPage}
        onPageChange={onPageChange}
        previousClassName={'page-item previous'}
        nextClassName={`page-item next ${currentPage >= Math.floor(totalCount / pageSize) ? 'disabled' : ''
          }`}
        nextLabel={
          <span className='page-link mx-2'>
            <i className='next'></i>
          </span>
        }
        previousLabel={
          <span className='page-link'>
            <i className='previous'></i>
          </span>
        }
        containerClassName={'align-items-center pagination'}
        pageClassName={'page-item mx-3'}
        pageLinkClassName={'page-link'}
        activeClassName={'active'}
      />
    </Container>
  )
}

export { Pagination }

const Container = styled.div`
  padding-block: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-info-container {
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .pagination-info {
    width: 200px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
`

export const pageSizeOptions = [
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: 1000,
    label: '1000',
  },
]
