/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, FC } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import styled from 'styled-components'
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa'
import { RiCloseLine } from 'react-icons/ri';
import { Option, OptionContainer } from '../../../components/MultiColumnSelect'
import { Range } from './WhiteBlackListing'
import { FilterTypes, ListTypes, PropertyTypes } from '../../filters/importProductsFilters'
import { useOnClickOutside } from '../../../utils/customHooks'
import { ButtonTertiarry } from '../../../components/CustomStyledComponents'
import { preventMinuses } from '../../../utils/inputFieldUtils'

type Props = {
  className?: string
  data?: Array<ProductForReview>
  title: string
  categories: Option[]
  brands: Option[]
  weightRange: Range
  volumeRange: Range
  priceRange: Range
  loading: boolean
  remove: (item: ProductForReview, listType: string) => void
  removeAll: (info: string) => void
  manageTitle: (title: string) => void
  manageOptions: (array: Option[], title: string) => void
  manageAllOptions: (info: string) => void
  manageRanges: (range: Range, title: string) => void
  manageData: (item: ProductForReview) => void
  manageAllData: () => void
}

export type ProductForReview = {
  id: string
  amazonProductId?: number
  imageUrl: string
  title: string
  category: string
  brand: string
  price: number
  weight: number
  volume: number
  selected: boolean
  asin: string
}

const ProductsReview: FC<Props> = ({
  data,
  title,
  categories,
  brands,
  weightRange,
  volumeRange,
  priceRange,
  loading,
  remove,
  removeAll,
  manageTitle,
  manageOptions,
  manageAllOptions,
  manageRanges,
  manageData,
  manageAllData,
}) => {
  const [activeCol, setActiveCol] = useState<string>('')
  const ref = useRef<any>()

  useOnClickOutside(ref, () => setActiveCol(''))

  function toggleCheckbox(item: Option, info: string) {
    let array = info === FilterTypes.Category ? categories : brands
    array = array.map((option: Option) => {
      if (option.id === item.id) {
        return {
          ...option,
          selected: !option.selected,
        }
      }
      return option
    })
    manageOptions(array, info)
  }

  function getOptions(info: string) {
    const array = info === FilterTypes.Category ? categories : brands
    return array.map((item: Option, index: number) => (
      <div
        key={index}
        className={`option ${item.selected && 'selected'}`}
        onClick={() => toggleCheckbox(item, info)}
      >
        <div className='form-check form-check-sm form-check-custom form-check-solid check'>
          <input
            id={`selected${index}`}
            name='selected'
            className='form-check-input'
            type='checkbox'
            value='1'
            checked={item.selected}
          />
        </div>
        <span>{`${item.title} (${item.amount})`}</span>
      </div>
    ))
  }

  function getTableRows() {
    return data?.map((item: ProductForReview, index: number) => (
      <tr key={index} className={item.selected ? `selected-row` : ''}>
        <td className='ps-5'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input widget-9-check'
              type='checkbox'
              value='1'
              checked={item.selected}
              onChange={(e) => manageData(item)}
            />
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <img src={item?.imageUrl} className='' alt='logo' />
            </div>
          </div>
        </td>
        <td>
          <span className='text-dark fw-bold  d-block mb-1 fs-6 w-150px'>{item?.title}</span>
        </td>
        <td>
          <span className='text-dark fw-bold d-block mb-1 fs-6'>{item?.category}</span>
        </td>
        {/* <td>
          <span className='text-dark fw-bold d-block fs-6'>{item?.brand}</span>
        </td> */}
        <td>
          <span className='fs-6 fw-bold'>{`$${item?.price}`}</span>
        </td>
        <td>
          <span className='fs-6 fw-bold'>{`${item?.weight} Ounces`}</span>
        </td>
        <td>
          <span className='fs-6 fw-bold'>{`${item?.volume} Inches`}</span>
        </td>
        <td className='p-0 m-0'>
          <div className='d-flex justify-content-start align-items-center ms-3'>
            <ButtonTertiarry
              className='me-5'
              onClick={() => remove(item, ListTypes.BlackList)}
              paddingx={15}
              paddingy={8.2}
              fontSize={12}
              fontWeight={500}
            >
              Add to Blacklist
            </ButtonTertiarry>
            {/* <div
              onClick={(e) => {
                remove(item, 'trash')
              }}
            >
              <span className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm button-gray'>
                <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
              </span>
            </div> */}
          </div>
        </td>
      </tr>
    ))
  }

  return (
    <Container>
      {/* begin::Body */}
      {/* begin::Table container */}
      <div className='table-responsive table-wrapper'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-6'>
          {/* begin::Table head */}
          <thead className='sticky-top table-row' ref={ref}>
            <tr className='fw-bolder'>
              <th className='w-25px ps-5 rounded-top-start'>
                <div className='form-check form-check-sm form-check-custom form-check-solid filter'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      value='1'
                      checked={data?.length ? data?.every((item) => item.selected) : false}
                      onChange={(e) => manageAllData()}
                    />
                  </div>
                  {activeCol === 'check' ? (
                    <FaChevronUp
                      className='ms-1 mt-1'
                      fontSize={13}
                      onClick={() => setActiveCol('')}
                    />
                  ) : (
                    <FaChevronDown
                      className='ms-1 mt-1'
                      fontSize={13}
                      onClick={() => setActiveCol('check')}
                    />
                  )}
                  {activeCol === 'check' && (
                    <div
                      className='filter-table filter-table-check'
                      onClick={() => removeAll(ListTypes.BlackList)}
                    >
                      <span>Add to Blacklist</span>
                      {/* <span onClick={() => removeAll('trash')}>Delete</span> */}
                    </div>
                  )}
                </div>
              </th>
              <th className='w-100px fw-bolder'>{'Picture'}</th>
              <th
                onClick={() => setActiveCol('title')}
                className={`w-175px fw-bolder ${activeCol === 'title' && 'active-column'}`}
              >
                <div className='d-flex filter'>
                  {`Title `}
                  <div className='ms-2'>
                    {title ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === 'title' && (
                    <div className='filter-table filter-table-title'>
                      <input
                        type='text'
                        className='form-control'
                        value={title}
                        onChange={(e) => manageTitle(e.target.value)}
                      />
                      <div className='search-icon'>
                        <FaSearch />
                      </div>
                      <div className='close-icon'>
                        <RiCloseLine onClick={() => manageTitle('')} />
                      </div>
                      <div className='mt-4'>
                        <span>Insert the title you are looking for</span>
                      </div>
                    </div>
                  )}
                </div>
              </th>
              <th
                onClick={() => setActiveCol(FilterTypes.Category)}
                className={`w-125px fw-bolder ${activeCol === FilterTypes.Category && 'active-column'
                  }`}
              >
                <div className='d-flex filter'>
                  {`Category `}
                  <div className='ms-2'>
                    {Object.values(categories).filter((item) => item.selected).length ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === FilterTypes.Category && (
                    <div className='filter-table filter-table-select'>
                      <OptionContainer>
                        <div className='clear-all'>
                          <span
                            className='button-gray'
                            onClick={() => manageAllOptions(FilterTypes.Category)}
                          >
                            Clear all
                          </span>
                        </div>
                        <div className='h-80 mt-10 overflow-auto'>
                          {getOptions(FilterTypes.Category)}
                        </div>
                      </OptionContainer>
                    </div>
                  )}
                </div>
              </th>
              {/* <th
                onClick={() => setActiveCol(FilterTypes.Brand)}
                className={`w-125px fw-bolder ${
                  activeCol === FilterTypes.Brand && 'active-column'
                }`}
              >
                <div className='d-flex filter'>
                  {`Brand `}
                  <div className='ms-2'>
                    {Object.values(brands).filter((item) => item.selected).length ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === FilterTypes.Brand && (
                    <div className='filter-table filter-table-select'>
                      <OptionContainer>
                        <div className='clear-all'>
                          <span
                            className='button-gray'
                            onClick={() => manageAllOptions(FilterTypes.Brand)}
                          >
                            Clear all
                          </span>
                        </div>
                        <div className='h-80 mt-10 overflow-auto'>
                          {getOptions(FilterTypes.Brand)}
                        </div>
                      </OptionContainer>
                    </div>
                  )}
                </div>
              </th> */}
              <th
                onClick={() => setActiveCol(PropertyTypes.Price)}
                className={`w-125px fw-bolder ${activeCol === PropertyTypes.Price && 'active-column'
                  }`}
              >
                <div className='d-flex filter text-nowrap'>
                  {`Purchase price `}
                  <div className='ms-2'>
                    {priceRange.from || priceRange.to ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === PropertyTypes.Price && (
                    <div className='filter-table filter-table-range'>
                      <span>{`From `}</span>
                      <input
                        type='number'
                        className='form-control'
                        value={priceRange.from}
                        placeholder='0.00'
                        onKeyDown={(e) => preventMinuses(e)}
                        min={0}
                        onChange={(e) =>
                          manageRanges(
                            { ...priceRange, from: e.target.value === '' ? '' : +e.target.value },
                            PropertyTypes.Price
                          )
                        }
                      />
                      <span>{`To `}</span>
                      <input
                        type='number'
                        className='form-control'
                        value={priceRange.to}
                        placeholder='0.00'
                        onKeyDown={(e) => preventMinuses(e)}
                        min={0}
                        onChange={(e) =>
                          manageRanges(
                            { ...priceRange, to: e.target.value === '' ? '' : +e.target.value },
                            PropertyTypes.Price
                          )
                        }
                      />
                      <span>{`USD`}</span>
                    </div>
                  )}
                </div>
              </th>
              <th
                onClick={() => setActiveCol(PropertyTypes.Weight)}
                className={`w-100px fw-bolder ${activeCol === PropertyTypes.Weight && 'active-column'
                  }`}
              >
                <div className='d-flex filter'>
                  {`Weight `}
                  <div className='ms-2'>
                    {weightRange.from || weightRange.to ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === PropertyTypes.Weight && (
                    <div className='filter-table filter-table-range'>
                      <span>{`From `}</span>
                      <input
                        type='number'
                        className='form-control'
                        value={weightRange.from}
                        placeholder='0.00'
                        onKeyDown={(e) => preventMinuses(e)}
                        min={0}
                        onChange={(e) =>
                          manageRanges(
                            { ...weightRange, from: e.target.value === '' ? '' : +e.target.value },
                            PropertyTypes.Weight
                          )
                        }
                      />
                      <span>{`To `}</span>
                      <input
                        type='number'
                        className='form-control'
                        value={weightRange.to}
                        placeholder='0.00'
                        onKeyDown={(e) => preventMinuses(e)}
                        min={0}
                        onChange={(e) =>
                          manageRanges(
                            { ...weightRange, to: e.target.value === '' ? '' : +e.target.value },
                            PropertyTypes.Weight
                          )
                        }
                      />
                      <span>{`Ounces`}</span>
                    </div>
                  )}
                </div>
              </th>
              <th
                onClick={() => setActiveCol(PropertyTypes.Volume)}
                className={`w-100px fw-bolder ${activeCol === PropertyTypes.Volume && 'active-column'
                  }`}
              >
                <div className='d-flex filter'>
                  {`Volume `}
                  <div className='ms-2'>
                    {volumeRange.from || volumeRange.to ? (
                      <img
                        src={toAbsoluteUrl('/media/svg/misc/filter-fill.svg')}
                        className='icon'
                      />
                    ) : (
                      <img src={toAbsoluteUrl('/media/svg/misc/filter.svg')} className='icon' />
                    )}
                  </div>
                  {activeCol === PropertyTypes.Volume && (
                    <div className='filter-table filter-table-range'>
                      <span>{`From `}</span>
                      <input
                        type='number'
                        className='form-control'
                        value={volumeRange.from}
                        placeholder='0.00'
                        onKeyDown={(e) => preventMinuses(e)}
                        min={0}
                        onChange={(e) =>
                          manageRanges(
                            { ...volumeRange, from: e.target.value === '' ? '' : +e.target.value },
                            PropertyTypes.Volume
                          )
                        }
                      />
                      <span>{`To `}</span>
                      <input
                        type='number'
                        className='form-control'
                        value={volumeRange.to}
                        placeholder='0.00'
                        onKeyDown={(e) => preventMinuses(e)}
                        min={0}
                        onChange={(e) =>
                          manageRanges(
                            { ...volumeRange, to: e.target.value === '' ? '' : +e.target.value },
                            PropertyTypes.Volume
                          )
                        }
                      />
                      <span>{`Inches`}</span>
                    </div>
                  )}
                </div>
              </th>
              <th className='w-200px rounded-top-end fw-bolder ps-5'>Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          {loading ? (
            <tbody className='loading'>
              <tr>
                <td>Loading...</td>
              </tr>
            </tbody>
          ) : data?.length ? (
            <tbody>{getTableRows()}</tbody>
          ) : (
            <tbody className='loading'>
              <tr>
                <td>Product list is empty...</td>
              </tr>
            </tbody>
          )}
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
      {/* begin::Body */}
    </Container>
  )
}

export { ProductsReview }

const Container = styled.div`
  thead {
    z-index: 1;
  }

  .table-wrapper {
    height: 60vh;
    overflow: auto;

    margin: 24px 12px;
    padding-inline: 12px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .button-gray {
    font-size: 12px;
    font-weight: 500;
    padding: 8.2px 15px;
    border-radius: 5px;
    background-color: #e4e6ef;
    cursor: pointer;
  }

  .button-gray:hover {
    background-color: #eff2f5;
  }

  .button-gray:active {
    background-color: #abadb8;
  }

  .table-row {
    font-size: 14px;
    padding: 60px;
    text-align: start;
    margin-block: auto;
    padding: 0 0;
    background-color: #d9f1fe;
    color: #333333;
  }

  .filter {
    position: relative;
  }

  .filter-table {
    position: absolute;
    top: 45px;
    width: 290px;
    height: 150px;
    display: flex;
    align-items: center;
    background-color: white;
    border: 2px solid #a1a5b7;
    border-radius: 5px;
  }

  .filter-table-title {
    left: -10px;
    flex-direction: column;
    height: 100px;
    padding-inline: 16px;
    input {
      margin-top: 20px;
      height: 30px;
      text-indent: 20px;
    }

    input:focus {
      border: 1px solid #2684ff;
      box-shadow: 0 0 1px 2px #c0e7fd;
    }
  }

  .filter-table-select {
    left: -10px;
    color: black;
    font-weight: 300;
    font-size: 12px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    overflow: auto;
    height: 300px;
  }

  .filter-table-range {
    right: 0;
    color: black;
    font-weight: 500;
    font-size: 12px;
    justify-content: space-around;
    width: 290px;
    overflow: auto;
    height: 80px;
    padding-inline: 16px;
    input {
      text-indent: 0px;
      height: 30px;
      font-size: 13px;
      width: 30%;
    }
  }

  .filter-table-check {
    color: black;
    font-weight: 300;
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    left: 0px;
    width: 150px;
    overflow: auto;
    height: 30px;
    span {
      margin-left: 10px;
    }
    cursor: pointer;
  }

  .filter-table-check:hover {
    color: #40b6f9;
  }

  // .active-column {
  //   background-color: #a9defd;
  // }

  .search-icon {
    position: absolute;
    top: 24px;
    left: 30px;
  }

  .close-icon {
    position: absolute;
    top: 24px;
    right: 25px;
    cursor: pointer;
  }

  .selected-row {
    background-color: #e4f5ff;
  }

  .check-all {
    border-radius: 5px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }

  .clear-all {
    position: absolute;
    top: 17px;
    right: 10px;
  }

  .rounded-top-start {
    border-top-left-radius: 8px;
  }

  .rounded-top-end {
    border-top-right-radius: 8px;
  }

  .icon {
    margin: 0 0 2px 0;
    cursor: pointer;
  }

  .loading {
    font-size: 18px;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
